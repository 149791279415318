import axios from "axios";
import { useState, useEffect } from "react";
const EdContent = () =>{
    const [data, setProjects] = useState(null);
    const [choosen, setChosenProject] = useState(1);
    const [cLink, setcLink] = useState(null);
    const [cSubtitle,setcSubtitle] = useState(null);
    const [cContent,setcContent] = useState(null);
    const [cConImg,setcConImg] = useState(null);
    const token = sessionStorage.getItem('token');
    const handleContentSubmit = async (event) =>{
        event.preventDefault();
        let base64Image = null;
        if (cConImg){
        base64Image = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result.split(',')[1]);
            console.log(cConImg)
            reader.readAsDataURL(cConImg);
        });
        }
        else{
            console.log(cConImg)
            base64Image = null;
        }
        const editContent = {
            contentid:chosenEntry,
            contentlink:cLink,
            subtitle:cSubtitle,
            contenttext:cContent,
            contentpicture:base64Image,
            projectdataid:choosen
        };
        console.log(editContent)
    // Send the data to the first API
    try {
      const response = await axios.post('https://api.alliknowisthatiknownothing.ch/api/EContent', editContent, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log(response.NewProjectData);
    } catch (error) {
      console.error(error);
      }
    } 


    useEffect(() => {
        // get api data
        const fetchData = async () => {
          const response = await fetch('https://api.alliknowisthatiknownothing.ch/api/PVM');
          const data = await response.json();
          setProjects(data);
          // define what data a project contains and set default project with id 1
          
    
        } ;
        fetchData();
    
      }, []);
      const project = data && data.$values.find((project) => project.gimmeproject.projectdataid === choosen);
     // Get entries based on selected project
      const entries = project && project.gimmeproject.projectcontents.$values;
    // Find the selected entry

useEffect(() => {
  if (entries && entries.length === 1) {
      setChosenEntry(entries[0].contentid);
  }
}, [entries]);

    const [chosenEntry, setChosenEntry] = useState(entries && entries.length > 0 ? entries[0].contentid : null);
    const selectedEntry  = entries && entries.find((entry) => entry.contentid === chosenEntry);
    if(!data) return<div className="ContentBox">  <div class=" loadingPos lds-roller posCenter"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>;
    return( 
    <div>
          <select className="SelectProject" onChange={(e) => setChosenProject(parseInt(e.target.value))}>
                {data && data.$values.map((project) => (
                    <option key={project.gimmeproject.projectdataid} value={project.gimmeproject.projectdataid}>
                        {project.gimmeproject.projectname}
                    </option>
                ))}
            </select>
            <select className="SelectProject" onChange={(e) => setChosenEntry(parseInt(e.target.value))}>
            {entries && entries.map((entry) => (
                <option key={entry.contentid} value={entry.contentid}>
                    {entry.subtitle}  {console.log(entry.contentid, entry.contentlink ,chosenEntry ,'c', "is id")/* Replace 'subtitle' with actual property name */}
                </option>
            ))}
        </select>

            {selectedEntry !== null && selectedEntry !== undefined && (    
<form className="UDForm" onSubmit={handleContentSubmit}>
    <label className="UserTextLabel" for="ConLink">Content Link</label>
    <input id="ConLink" type="text"  className="UserTextInput" placeholder={selectedEntry.contentlink}
     onChange={(event) => setcLink(event.target.value)} value={cLink}
    ></input>

    <label className="UserTextLabel" for="SubTit">Subtitle</label>
    <input id="SubTit" type="text"  className="UserTextInput" placeholder={selectedEntry.subtitle}
    onChange={(event) => setcSubtitle(event.target.value)} value={cSubtitle}
    ></input>

    <label className="UserTextLabel" for="ConText">Content</label>
    {/*<div> curent Content</div>*/}
    <textarea id="ConTex"  className="UserTextInput" placeholder="pain pain go away.."
    onChange={(event) => setcContent(event.target.value)} value={cContent}
    ></textarea>

    <label className="UserTextLabel" for="CPic">Upload file</label>
    <input id="CPic" className="UpFileInput" type="file"
    onChange={(event) => setcConImg(event.target.files[0])}
    ></input>
    <button type="submit" className="SelectEUBtn">Submit</button>
    </form>)}
        </div>
    );
    }
    export default EdContent;