import { useEffect, useState } from "react";
import '../Styles/private.css';
import axios from "axios";
const UpProject = () => {
    const [data, setProjects] = useState(null);
    const [selectedProject,setSelectedProject] = useState(null);
    const [pDate,setpDate] = useState (null);
    const [pSection,setpSection] = useState(null);
    const [pName,setpName] = useState(null);
    const [pDesc,setpDesc] = useState(null);
    const [pImage, setpImage] = useState(null);
    const [pAuthor, setpAuthor] = useState(null);
    const [pSumary, setpSummary] = useState(null);
    const [pUrl, setpUrl] = useState(null);
    const token = sessionStorage.getItem('token');

    const handleProjectSubmit = async (event) =>{
      event.preventDefault();
      let base64Image = "";
      // If an image was selected, convert it to a Base64 string
      if (pImage) {
        base64Image = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result.split(',')[1]);
          reader.readAsDataURL(pImage);
        });
      }
      else{
        base64Image = null;
      }
       // Convert the date to an ISO string
       let isoDate = null;
       if(pDate){
       try {
          isoDate = new Date(pDate).toISOString();
         //isoDate = pDate.toISOString();
       } catch (error) {
         console.error("Invalid date:", pDate);
         // Handle the error appropriately here
         // For example, you might want to set isoDate to a default value or stop the function execution
       }
       }

      // Create a new object to store the form data
      const NewProjectData = {
      date: isoDate,
      section: pSection,
      projectname: pName,
      description: pDesc,
      userid:1,
      projectpicture:base64Image,
      author:pAuthor,
      summary:pSumary,
      projectlink:pUrl
      
    };
    console.log(NewProjectData);
    console.log(token);
    // Send the data to the first API
    try {
      const response = await axios.post('https://api.alliknowisthatiknownothing.ch/api/UProject', NewProjectData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log(response.NewProjectData);
    } catch (error) {
      console.error(error);
    }
    }
    useEffect(() => {
        // get api data
        const fetchData = async () => {
          const response = await fetch('https://api.alliknowisthatiknownothing.ch/api/PVM');
          const data = await response.json();
          setProjects(data);
          // define what data a project contains and set default project with id 1
          
        }

        fetchData();

        
   
      }, []);
     // const project = data && data.$values.find((project) => project.gimmeproject.projectdataid === choosen);
return(
    <>
      
        <form onSubmit={handleProjectSubmit} className="UDForm">
        <label className="UserTextLabel" for="UPDate">Project Date</label>
        <input className="UpDateInput" id="UPDates" type="date"
        onChange={(event) => setpDate(event.target.value)} value={pDate}
        ></input>

        <label className="UserTextLabel" for="UPSection">Section</label>
        <input id="UPSection" type="text"className="UserTextInput" placeholder="section belong?"
        onChange={(event) => setpSection(event.target.value)} value={pSection}
        ></input>

        <label className="UserTextLabel" for="UPProjectName">Projectname</label>
        <input id="UPProjectName" type="text" className="UserTextInput" placeholder="name?"
        onChange={(event) => setpName(event.target.value)} value={pName}
        ></input>

        <label className="UserTextLabel" for="UPDesc">Description</label>
        <input id="UPDesc" type="text" className="UserTextInput" placeholder="new description?"
        onChange={(event) => setpDesc(event.target.value)} value={pDesc}
        ></input>
        {/*
        <label className="UPLabel" for="USUser">Assigned User</label>
        <select id="USUser" className="SelectEUBtn">   <option>Meow</option></select>
      */}
        <label className="UserTextLabel" for="UPPicture">Project Picture</label>
        <input id="UPPicture" className="UpFileInput" type="file"
        onChange={(event) => setpImage(event.target.files[0])}
        ></input>

        <label className="UserTextLabel" for="UPAuthor">Author</label>
        <input id="UPAuthor" type="text" className="UserTextInput" placeholder="author"
         onChange={(event) => setpAuthor(event.target.value)} value={pAuthor}
        ></input>

        <label className="UserTextLabel" for="UPSummary">Summary
        </label>
        <input id="UPSummary" type="text" className="UserTextInput" placeholder="new summary"
         onChange={(event) => setpSummary(event.target.value)} value={pSumary}
        ></input>

        <label className="UserTextLabel" for="UPURL">Project Link Url</label>
        <input id="UPURL" type="text" className="UserTextInput" placeholder="project url"
         onChange={(event) => setpUrl(event.target.value)} value={pUrl}
        ></input>
        <button className="SelectEUBtn" type="submit">submit</button>
        </form>
       </>
    
);
}
export default UpProject;