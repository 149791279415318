import { useCallback, useEffect, useState } from "react";
import picture from "./download.jpg"
import { getUserData } from "./api";
import { PowerGlitch } from 'powerglitch'
import axios from "axios";



const Content = () => {
    const [users,setUserData] = useState([]);
    const [icon,setIcon] = useState(null);
    useEffect(() => {
        // Read out data in theory
        async function fetchUserdata(){
            // try to acess api
            try{
            
            const response = await axios.get('https://api.alliknowisthatiknownothing.ch/api/Userdatums/1',{
                headers:{
                    'Content-Type': 'application/json',
                    'Accept': 'application/jason' 
                }
            });
            const data = response.data;
            setUserData(Array.isArray(data) ? data : [data]);
            const imageData = Uint8Array.from(atob(data.usericon), (c) => c.charCodeAt(0));
            const blob2 = new Blob([imageData], {type: 'image/jpg'});
            const Iurl = URL.createObjectURL(blob2);
            setIcon(Iurl);
            }
            catch(error){
            console.error(error);
            }
        }

     
    fetchUserdata();
       // libary for glitch effect cause lazy
      //  if(!==icon)return PowerGlitch.glitch('#glitched-image',{playMode:'hover'},
       //{imageUrl:{Iurl}})
   
        }, []);
    
    if(!icon) return<div className="ContentBox"> <div class="lds-roller loadingPos"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>;
  
    return(
     <div className="ContentBox">
     
        <span className="SpanPositioning">
          
           
        <picture>
        <img className="FrontIcon" id="glitched-image" src={icon} ></img>
       {/* <img className="FrontIcon" id="glitched-image" src={picture} alt="sorry couldn't revice User icon"></img> */}

        </picture>
        {users.map(user => (
            <span key={user.userid}>
        <p className="MediumFont">Username:<br/>{user.username}</p>
        <p className="NPCsFont">Description:<br/>{user.userinfo}</p>
        </span>))} 
        <h3>Tech stack</h3>
        <p>C#, JS, .NET, HTML, CSS, SQL, React, Entity Framework</p>
        </span>
        
     </div>
    );
}
export default Content