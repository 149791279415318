import { useState, useEffect } from "react";
import axios from "axios";
const UpContent = () =>{
    const [data, setProjects] = useState(null);
    const [choosen, setChosenProject] = useState(1);
    const [cLink, setcLink] = useState(null);
    const [cSubtitle,setcSubtitle] = useState(null);
    const [cContent,setcContent] = useState(null);
    const [cConImg,setcConImg] = useState(null);
    const token = sessionStorage.getItem('token');

    const handleContentSubmit = async (event) =>{
        event.preventDefault();
        let base64Image = null;
        if (cConImg){
        base64Image = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result.split(',')[1]);
            console.log(cConImg)
            reader.readAsDataURL(cConImg);
        });
        }
        else{
            console.log(cConImg)
            base64Image = null;
        }
        const newContent = {
            contentlink:cLink,
            subtitle:cSubtitle,
            contenttext:cContent,
            contentpicture:base64Image,
            projectdataid:choosen
        };
        console.log(newContent)
    // Send the data to the first API
    try {
      const response = await axios.post('https://api.alliknowisthatiknownothing.ch/api/UContent', newContent, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log(response.newContent);
    } catch (error) {
      console.error(error);
      }
    } 

    useEffect(() => {
        // get api data
        const fetchData = async () => {
          const response = await fetch('https://api.alliknowisthatiknownothing.ch/api/PVM');
          const data = await response.json();
          setProjects(data);
          // define what data a project contains and set default project with id 1
          
    
        } ;
        fetchData();
    
        
      }, []);
      const project = data && data.$values.find((project) => project.gimmeproject.projectdataid === choosen);
   
return( <div>
     <select className="SelectProject" onChange={(e) => setChosenProject(parseInt(e.target.value))}>
                {data && data.$values.map((project) => (
                    <option key={project.gimmeproject.projectdataid} value={project.gimmeproject.projectdataid}>
                        {project.gimmeproject.projectname}
                    </option>
                ))}
            </select>

            { project && (      
<form className="UDForm" onSubmit={handleContentSubmit}>
    <label className="UserTextLabel" for="ConLink">Content Link</label>
    <input id="ConLink" type="text"  className="UserTextInput" required
     onChange={(event) => setcLink(event.target.value)} value={cLink}
    ></input>

    <label className="UserTextLabel" for="SubTit">Subtitle</label>
    <input id="SubTit" type="text"  className="UserTextInput" required
    onChange={(event) => setcSubtitle(event.target.value)} value={cSubtitle}
    ></input>

    <label className="UserTextLabel" for="ConText">Content</label>
    {/*<div> curent Content</div>*/}
    <textarea id="ConTex" className="UserTextInput" required
    onChange={(event) => setcContent(event.target.value)} value={cContent}
    ></textarea>

    <label className="UserTextLabel" for="CPic">Upload file</label>
    <input id="CPic" className="UpFileInput" type="file" required
    onChange={(event) => setcConImg(event.target.files[0])}
    ></input>
    <button type="submit" className="SelectEUBtn">Submit</button>
    </form>)}</div>
);
}
export default UpContent;