import axios from "axios";
import { useEffect, useState } from "react";

const EdProject = () =>{
    
    const [data,setProjects] = useState(null);
    const [selectedProject,setSelectedProject] = useState(null);
   const [choosen, setChosenProject] = useState(1);
   const [pDate,setpDate] = useState (null);
   const [pSection,setpSection] = useState(null);
   const [pName,setpName] = useState(null);
   const [pDesc,setpDesc] = useState(null);
   const [pImage, setpImage] = useState(null);
   const [pAuthor, setpAuthor] = useState(null);
   const [pSumary, setpSummary] = useState(null);
   const [pUrl, setpUrl] = useState(null);
   const token = sessionStorage.getItem('token');
   const handleProjectSubmit = async (event) => {
    event.preventDefault();
    let base64Image = "";
    // If an image was selected, convert it to a Base64 string
    if (pImage) {
      base64Image = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.readAsDataURL(pImage);
      });
    } else {
      base64Image = null;
    }
  
    // Convert the date to an ISO string
    let isoDate = null;
    if(pDate){
    try {
       isoDate = new Date(pDate).toISOString();
      //isoDate = pDate.toISOString();
    } catch (error) {
      console.error("Invalid date:", pDate);
      // Handle the error appropriately here
      // For example, you might want to set isoDate to a default value or stop the function execution
    }
    }
    // Create a new object to store the form data
    const editedProjectData = {
      projectdataid:choosen,
      date: isoDate, // Use the ISO date string here
      section: pSection,
      projectname: pName,
      description: pDesc,
      userid:1,
      projectpicture:base64Image,
      author:pAuthor,
      summary:pSumary,
      projectlink:pUrl
    };
    console.log(isoDate, "date or so hopes");
    console.log(editedProjectData);
    console.log(token);
    
    // Send the data to the first API
    try {
      const response = await axios.post('https://api.alliknowisthatiknownothing.ch/api/EProject', editedProjectData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log(response.editedProjectData);
    } catch (error) {
      console.error(error);
    }
  }  
    useEffect(() => {
        //const [data,setPr] = useState(null);
       const fetchData = async () => {
            const response = await fetch('https://api.alliknowisthatiknownothing.ch/api/PVM');
            const data = await response.json();
            setProjects(data);
           // const project = await data.$values.find((project)=> project.gimmeproject.projectdataid === choosen) ;
            //setSelectedProject(project);
        }
        fetchData();
        // const project = data && data.$values.find((project) => project.gimmeproject.projectdataid === choosen);
    
    }, []);
    const project = data && data.$values.find((project) => project.gimmeproject.projectdataid === choosen);
     // lodaing animation css & html only 
 if(!data) return<div className="ContentBox">  <div class=" loadingPos lds-roller posCenter"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>;
   return( 
    <div>
    
    <select className="SelectProject"  onChange={(e) => setChosenProject(parseInt(e.target.value))}>
                {data && data.$values.map((project) => (
                    <option key={project.gimmeproject.projectdataid} value={project.gimmeproject.projectdataid}>
                        {project.gimmeproject.projectname}
                    </option>
                ))}
            </select>

            { project && (        
        
        <form  onSubmit={handleProjectSubmit} className="UDForm" key={project.gimmeproject.projectdataid}>
            <label className="UserTextLabel" htmlFor="PNE">Projectname</label>
            <input id="PNE" type="text" className="UserTextInput" placeholder={project.gimmeproject.projectname}
                onChange={(event) => setpName(event.target.value)} value={pName}
            ></input>
           
            <label  htmlFor="SUME" className="UserTextLabelBig">Summary (current summary) <br/>{project.gimmeproject.summary}</label>
            <input id="SUME" className="UserTextInput" type="text" placeholder={project.gimmeproject.summary}
            onChange={(event) => setpSummary(event.target.value)} value={pSumary}
            ></input>

            <label className="UserTextLabel" for="DAE">Project creation</label>
            <input id="DAE" className="UpDateInput" type="date"
                onChange={(event) => setpDate(event.target.value)} value={pDate}
            ></input>

            <label className="UserTextLabel" for="SEE">Section</label> 
            <input id="SEE" className="UserTextInput" type="text"  placeholder={project.gimmeproject.section}
                onChange={(event) => setpSection(event.target.value)} value={pSection}
            ></input>

            <label htmlFor="DESCE" className="UserTextLabelBig">Description (current description)<br/>{project.gimmeproject.description}</label>
            <input id="DESCE" className="UserTextInput" type="text" placeholder={project.gimmeproject.description}
              onChange={(event) => setpDesc(event.target.value)} value={pDesc}
            ></input>

            <label className="UserTextLabel" for="AUTE">Author</label>
            <input id="AUTHE" className="UserTextInput" type="text" placeholder={project.gimmeproject.author}
                onChange={(event) => setpAuthor(event.target.value)} value={pAuthor}
            ></input>
            
            <label className="UserTextLabel" htmlFor="LINKE">Project link</label>
            <input id="LINKE" className="UserTextInput" type="text" placeholder={project.gimmeproject.projectlink}
               onChange={(event) => setpUrl(event.target.value)} value={pUrl}
            ></input>

            <label className="UserTextLabel" for="PPICE">Upload picture</label>
            <input id="PPICE" type="file" className="UpFileInput"
             onChange={(event) => setpImage(event.target.files[0])}
            ></input>
            <button type="submit" className="SelectEUBtn">Submit</button>
            </form>
            )}
        </div>
   
    

    );
}
    export default EdProject;