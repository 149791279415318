import { useState } from 'react';
import { Navigate, json } from 'react-router-dom';
//import {sessionReducer } from 'redux-react-session';
const Login = () => {
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [data,setData] = useState('');
    const [isAuthenticate,setIsAthenticated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        // Send the login data to the API
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Username: username, Passwordhash: password })
        };

        fetch('https://api.alliknowisthatiknownothing.ch/api/Login', requestOptions)
            .then(response => {
               
               if(response.ok){
                setIsAthenticated(true);
                    sessionStorage.setItem('isAuthenticated', true);
                   console.log(response);
                    return response.json();
               }
               else{
                throw new Error("login failed")
               }
            })
            .then(data =>{
                const token = data.token;
                console.log(token);
                sessionStorage.setItem('token',token);
              //  window.location.replace('/dashboard'); //under for web server fck up 
             window.location.replace('/dashboard');
            })
            .catch(error =>{
                console.error('Error:', error);
            
            });
    }

    return (
        <div className="ContentBox">
            <span className="SpanPositioning">
                <form className="LoginForm" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Username"
                        className="SizeBtnsLogin"
                        value={username}
                        onChange={event => setUsername(event.target.value)}
                    />

                    <input
                        type="password"
                        placeholder="Passcode"
                        className="SizeBtnsLogin"
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                    />

                    <button type="submit" className="SizeBtnsLogin SubmitBtn"> Login?!£?</button>
                </form>
            </span>
        </div>
    );
}

export default Login;