import { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{Mousewheel, Navigation, Pagination,A11y,Scrollbar} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './index.css';

const Projects = () => {
  const [data, setProjects] = useState(null);
  const [selectedProject,setSelectedProject] = useState(null);
  const [blob,setBlob] = useState(null);
  const [conImg,setconImag] = useState(null);
  const [conImgObj,setConImgObj] = useState(null);
  useEffect(() => {
    // get api data
    const fetchData = async () => {
      const response = await fetch('https://api.alliknowisthatiknownothing.ch/api/PVM');
      const data = await response.json();
      setProjects(data);
      // define what data a project contains and set default project with id 1
      const project = await data.$values.find((project)=> project.gimmeproject.projectdataid === 1) ;
      setSelectedProject(project);
        // get the data to display the project blob later on
        const imageData = Uint8Array.from(atob(project.gimmeproject.projectpicture), (c) => c.charCodeAt(0));
        const blob2 = new Blob([imageData], {type: 'image/jpg'});
        const url = URL.createObjectURL(blob2);
        setBlob(url);
        // get the data to display content entry blobs sucessfully
        const contentImgUrls = project.gimmeproject.projectcontents.$values
        .sort((a, b)=> b.contentid - a.contentid)
        .map((content)=>{
        // check if blob belongs to the project and to include in in default project 
        if(content.contentpicture && content.projectdataid === project.gimmeproject.projectdataid){
          const contentImgData = Uint8Array.from(
            atob(content.contentpicture),
            (c) => c.charCodeAt(0)
          );
          const contentBlob = new Blob([contentImgData], {type: "image/jpg"});
          return URL.createObjectURL(contentBlob);
        }
        return null;
      });
      setconImag(contentImgUrls);
      // make sure the blobs are displayed in the right order (at least i hope this wokks)
      let conImgObj = {};
        if(contentImgUrls){
          // check the loop index and assign the content id to it
          contentImgUrls.forEach((img,index) =>{
          let contentid = 
          project.gimmeproject.projectcontents.$values[index].contentid;
          conImgObj[contentid] = img;
      })
    }setConImgObj(conImgObj);
    };

    fetchData();
    
    
  }, []);
  // button click funtions for project selection
  const handleClick = async (id)=>{
    if(!data) return;// meow??
    // get project contents on click aswell since the first time its only there for the default project
    const project = await data.$values.find((project)=> project.gimmeproject.projectdataid === id) ;
    setSelectedProject(project);
    // if the picture exists execute following code wow to set picture
    if(project.gimmeproject.projectpicture){
    const imageData = Uint8Array.from(atob(project.gimmeproject.projectpicture), (c) => c.charCodeAt(0));
    const blob2 = new Blob([imageData], {type: 'image/jpg'});
    const url = URL.createObjectURL(blob2);
    setBlob(url);
  };
  // here i look for the content picture again
  const contentImgUrls2 = project.gimmeproject.projectcontents.$values
      .sort((a, b)=> b.contentid - a.contentid)
      .map((content)=>{
        if(content.contentpicture && content.projectdataid === project.gimmeproject.projectdataid){
          const contentImgData = Uint8Array.from(
            atob(content.contentpicture),
            (c) => c.charCodeAt(0)
          );
          const contentBlob = new Blob([contentImgData], {type: "image/jpg"});
          return URL.createObjectURL(contentBlob);
        }
        return null;
      });
      setconImag(contentImgUrls2);
      // here i look again for the index and assign it to content id since this is on click the other is only default project
      let conImgObj = {};
      if(contentImgUrls2){
        contentImgUrls2.forEach((img,index) =>{
          let contentid = 
          project.gimmeproject.projectcontents.$values[index].contentid;
          conImgObj[contentid] = img;
        })
      }setConImgObj(conImgObj);
      
    }
 // lodaing animation css & html only 
 if(!data) return<div className="ContentBox">
    <span className="SpanPositioning">
  <h1>
 Portfolio, Experience, Projects
</h1></span>
<div class="lds-roller loadingPos"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div>;
// return html code with the data
  return (
    <div className="ContentBox">
      <span className="SpanPositioning">
        <h1>
          Portfolio, Experience, Projects
        </h1>
        {/*Swiper is a libary for my button carusel*/}
         <Swiper
      spaceBetween={30}
      slidesPerView={1}
      loop={true}
      navigation={true}
      pagination={{clickable:true,}}
      mousewheel={true}
      modules={[Pagination,Navigation,Mousewheel]}
      className="mySwiper"
    >
      {data.$values.map((project) => (
        <SwiperSlide key={project.gimmeproject.projectdataid}>
          <button
            className="ProjectSelectionBtn"
            onClick={() => handleClick(project.gimmeproject.projectdataid)}
          >
            {project.gimmeproject.projectname}
          </button>
        </SwiperSlide>
      ))}
    </Swiper>
      </span>
      {selectedProject && (
        <div className="MyProjectContent">
          <h2>{selectedProject.gimmeproject.projectname}</h2>     
          <p>{selectedProject.gimmeproject.summary}</p>
          {blob && (
          <picture className="">
            <img className="ProjectImg" src={blob} alt="project picture not available">
            </img>          
          </picture>)}
          <p>{selectedProject.gimmeproject.description}</p>
          {selectedProject.gimmeproject.projectcontents.$values
          .sort((a,b)=> a.contentid - b.contentid)
          .map((content, index)=>(
            <div className="MarkDownText" key={content.contentid}>
            
              <p className="ProjectSubT">{content.subtitle}</p>
              {conImgObj[content.contentid] && (
              <picture className="">
            <img className="ProjectImg" src={conImgObj[content.contentid]} alt="picture not available">
            </img>          
          </picture>)}
             <p> <ReactMarkdown>{content.contenttext}</ReactMarkdown></p>
            </div>
          ))}
        </div>
      )}
    </div>
  );

};

export default Projects;