import { Redirect,Navigate, useNavigate } from 'react-router-dom';
import React, { useState, useAuth } from "react";

const Navbar2 = ({ setSite }) => {
  const [selectedButton, setSelectedButton] = useState("BTN1");
  const navigate = useNavigate();
  const handleLinkClick = (newSite, buttonId) => {
    setSite(newSite);
    setSelectedButton(buttonId);
    if(newSite === "logout"){
        sessionStorage.setItem('isAuthenticated',false);
        console.log(sessionStorage.getItem('isAuthenticated'))
        navigate('/');
        
    }
   
  };

  return (
    <nav className="Navbar PNN">
      <button
        id="BTN1"
        className={`NavButton NavBtn PN ${selectedButton === "BTN1" ? "selected" : ""}`}
        onClick={() => handleLinkClick("dashboard", "BTN1")}
      >
        Dashboard
      </button>
      <button
        id="BTN2"
        className={`NavButton NavBtn${selectedButton === "BTN2" ? "selected" : ""}`}
        onClick={() => handleLinkClick("upload", "BTN2")}
      >
        Upload
      </button>
      <button
        id="BTN4"
        className={`NavButton NavBtn${selectedButton === "BTN4" ? "selected" : ""}`}
        onClick={() => handleLinkClick("edit", "BTN4")}
      >
        Edit
      </button>
      <button
        id="BTN3"
        className={`NavButton NavBtn PN ${selectedButton === "BTN3" ? "selected" : ""}`}
        onClick={() => handleLinkClick("logout", "BTN3")}
      >
        Logout
      </button>
    </nav>
  );
};

export default Navbar2;