import { useEffect, useState,useRef } from "react";
import axios from "axios";
import '../Styles/private.css';
import '../index.css';
const User =()=>{

const [users,setUserData] = useState([]);
const [icon,setIcon] = useState(null);
const inputRef = useRef();
const [username, setUsername] = useState(null);
const [password, setPassword] = useState(null);
const [links, setLinks] = useState(null);
const [description, setDesc] = useState(null);
const [image, setImage] = useState(null);
const token = sessionStorage.getItem('token');
const handleClick = () =>{
    inputRef.current.click();
};
const handleChangeInput = (event) => {
    // Validate the form data
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    let base64Image = "";
    // If an image was selected, convert it to a Base64 string
    if (image) {
      base64Image = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.readAsDataURL(image);
      });
    }
    else{
      base64Image = null;
    }
  
    // Create a new object to store the form data
    const data = {
      userid: 1,
      username: username,
      passwordhash: password,
      userinfo: description,
      links: links,
      usericon: base64Image
    };
    console.log(data);
    console.log(token);
    // Send the data to the first API
    try {
      const response = await axios.post('https://api.alliknowisthatiknownothing.ch/api/User', data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
useEffect(() => {
   
    // Read out data in theory
    async function fetchUserdata(){
        // try to acess api
        try{
        
        const response = await axios.get('https://api.alliknowisthatiknownothing.ch/api/Userdatums/1',{
            headers:{
                'Content-Type': 'application/json',
                'Accept': 'application/jason' 
            }
        })
        const data = response.data;
        setUserData(Array.isArray(data) ? data : [data]);
        const imageData = Uint8Array.from(atob(data.usericon), (c) => c.charCodeAt(0));
        const blob2 = new Blob([imageData], {type: 'image/jpg'});
        const Iurl = URL.createObjectURL(blob2);
        setIcon(Iurl);
        }
        catch(error){
        console.error(error);
        }
    }

 
fetchUserdata();
   // libary for glitch effect cause lazy
  //  if(!==icon)return PowerGlitch.glitch('#glitched-image',{playMode:'hover'},
   //{imageUrl:{Iurl}})

    }, []);

if(!icon) return<div className="ContentBox"> <div class="lds-roller loadingPos"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>;
return(
    <div className="ContentBox">
    
       <span className="SpanPositioning">
         
          <div className="UploadIcon" onClick={handleClick}>
       <picture>
       <img className="InsideIcon" id="glitched-image" src={icon} ></img>
      {/* <img className="FrontIcon" id="glitched-image" src={picture} alt="sorry couldn't revice User icon"></img> */}

       </picture>
       </div>
       {users.map(user => (
           <form onSubmit={handleSubmit} key={user.userid} className="UDForm">
            <label for="UNID" className="UserTextLabel">Username:</label>
       <input id="UNID" className="UserTextInput" placeholder={user.username + "  <- current name"}
        onChange={(event) => setUsername(event.target.value)} value={username}></input>

       <label for="Udesc" className="UserTextLabelBig">Description:<br/>{user.userinfo}</label>
       <input id="Udesc" className="UserTextInput"type="text" placeholder="new random description!?"
        onChange={(event) => setDesc(event.target.value)} value={description}></input>

       <label for="UPW" className="UserTextLabel">Passcode or so:</label>
        <input id="UPW" className="UserTextInput"type="password"  placeholder="what's this? passwords????"
        onChange={(event) => setPassword(event.target.value)} value={password}></input>

       <label for="Ulink" className="UserTextLabel">Links:</label>
       <input id="Ulink" className="UserTextInput" type="text" placeholder={user.links}
       onChange={(event) => setLinks(event.target.value)} value={links}></input>

       <input type="file" ref={inputRef} style={{display: 'none'}}onChange={(event) => {
        handleChangeInput(event);setImage(event.target.files[0]);}}></input>
       <button className="SelectEUBtn" type="submit">Submit</button>
       </form>))} 

       </span>
    
  
      
    </div>
   );
}
export default User;