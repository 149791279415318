import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const BackgroundEffect = () => {
  // Create a reference to the canvas element
  const canvasRef = useRef(null);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [fontSize, setFontSize] = useState(Math.max(Math.floor(windowDimensions.width / 100), 20));
  console.log(fontSize + "init");
  const [columns, setColumns] = useState(Math.floor(windowDimensions.width / fontSize * 3 ));
  //var columns = fontSize;
  const [rainDrops, setRainDrops] = useState(Array(columns).fill(1));
//event listener 
// on resize -> update canvas width and height , use canvasref. current
// then redo calc columns owo
// do forloop to set rain drop
//then it will be broken and i will blame wolfii :) 
//what does the i in wolfii stands for i am wolfi I am I... 

  



  useEffect(() => {
   
    // Get the canvas and context objects
    var canvas = canvasRef.current;
    var context = canvas.getContext('2d');
  
    //window.innerWidth = windowDimensions.width;
  //window.innerHeight = windowDimensions.height;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
   
    // Define the characters to use in the animation
    const katakana =
      'アァカサタナハマヤャラワガザダバパイィキシチニヒミリヰギジヂビピウゥクスツヌフムユュルグズブヅプエェケセテネヘメレヱゲゼデベペオォコソトノホモヨョロヲゴゾドボポヴッン';
    const latin = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const nums = '0123456789';

    // Combine all characters into a single string
    const alphabet = katakana + latin + nums;
 
    // Initialize an array to keep track of the rain drops
    const rainDrops = [];

    // Set the initial position of each rain drop
    for (let initialPosition = 0; initialPosition < columns; initialPosition++) {
      rainDrops[initialPosition] = 1;
    }

    const draw = () => {
      console.log("MeowMeow")
   
  
      // Clear the canvas and fill it with a transparent color
      context.fillStyle = 'rgba(242,242,242,0.03)';
      context.fillRect(0, 0, canvas.width, canvas.height);

      // Set the fill style and font for drawing text
      context.fillStyle = 'rgba(255,105,180,0.4)';
      context.font = fontSize / 6 + 'vw monospace,sans-serif';

      // Draw each rain drop
      for (let drop = 0; drop < columns; drop++) {
        // Choose a random character from the alphabet
        const text = alphabet.charAt(Math.floor(Math.random() * alphabet.length));
        // Draw the character at the current position of the rain drop
        context.fillText(text, drop * fontSize, rainDrops[drop] * fontSize );

        // If the rain drop has reached the bottom of the canvas,
        // reset its position with a small probability
        if (rainDrops[drop] * fontSize > window.innerHeight && Math.random() > 0.975) {
          rainDrops[drop] = 0;
        }
        // Move the rain drop down by one step
        rainDrops[drop]++;
      }
     
      
    };

    // Set up an interval to call the draw function repeatedly
    const animationId = setInterval(draw, 20);
  
    // Return a cleanup function to stop the animation when the component unmounts
    return () => {
      clearInterval(animationId);
      
     // window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <canvas ref={canvasRef} className='matrix-container' id="MTX"/>;
};

export default BackgroundEffect;


