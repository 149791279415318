/*import Projects from "./Projects";
import App  from "./App";
import { useState } from "react";
import { click } from "@testing-library/user-event/dist/click";

const Navbar = ({setSite}) => {

const handleLinkClick = (newSite) => {
    setSite(newSite);

   
  
};
    return(
        <nav className="Navbar">
            
                <button id="BTN1" className="NavButton NavBtn" onClick={() => handleLinkClick('home')}> 
                Home 
                </button>
                <button id="BTN2" className="NavButton NavBtn" onClick={() => handleLinkClick('projects')}> 
                Projects
                </button>    
                <button id="BTN3"className="NavButton NavBtn" onClick={() => handleLinkClick('login')}>
                Login
                </button>
            
        </nav>
        
    );
}
export default Navbar
*/
import React, { useState } from "react";

const Navbar = ({ setSite }) => {
  const [selectedButton, setSelectedButton] = useState("BTN1");

  const handleLinkClick = (newSite, buttonId) => {
    setSite(newSite);
    setSelectedButton(buttonId);
  };

  return (
    <nav className="Navbar">
      <button
        id="BTN1"
        className={`NavButton NavBtn ${selectedButton === "BTN1" ? "selected" : ""}`}
        onClick={() => handleLinkClick("home", "BTN1")}
      >
        Home
      </button>
      <button
        id="BTN2"
        className={`NavButton NavBtn ${selectedButton === "BTN2" ? "selected" : ""}`}
        onClick={() => handleLinkClick("projects", "BTN2")}
      >
        Projects
      </button>
      <button
        id="BTN3"
        className={`NavButton NavBtn ${selectedButton === "BTN3" ? "selected" : ""}`}
        onClick={() => handleLinkClick("login", "BTN3")}
      >
        Login
      </button>
    </nav>
  );
};

export default Navbar;