import '../src/Styles/Matrix.css';
import '../src/Styles/Root.css';
import React,{useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Dashboard from './Dashboard';
import Navbar from './Navbar';
import Content from './Content';
import MatrixEffect from './MatrixEffect';
import Projects from './Projects';
import  Login from './Login';
import Navbar2 from './Navbar2';
import User  from './PrivateZone/User';
import Upload from './PrivateZone/Upload';
import Edit from './PrivateZone/Edit';
const App =() =>{
  const[Site, setSite] = useState('home');
  
  return (

    <div className="App">
      {/* here the different components are inserted */}
   
      <Routes>  <Route path="/dashboard" element={<Dashboard setSite={setSite}/>}/>
     
    {/*  <Route path="/dashboard" element={<Dashboard setSite={setSite}/>}/>*/}
      <Route path="/" element={<Navbar setSite={setSite}/>}>
      </Route>
      </Routes>
     <MatrixEffect></MatrixEffect>
  {/*   <Navbar setSite={setSite}></Navbar>*/} 
      {Site === 'home' && <Content></Content>}
      {Site === 'projects' && <Projects></Projects>}
      {Site === 'login' && <Login></Login>}
      {Site === 'dashboard' && <Navbar2 setSite={setSite}></Navbar2>}
      {Site === 'dashboard' && <User></User>}
      {Site === 'upload' && <Navbar2 setSite={setSite}></Navbar2>}
      {Site === 'upload' && <Upload></Upload>}
      {Site === 'edit' && <Navbar2 setSite={setSite}></Navbar2>}
      {Site === 'edit' && <Edit></Edit>}
      <div className='content'>
      </div>
    </div>
    
   
  );
}

export default App;
