import { Redirect,Navigate } from 'react-router-dom';
import Navbar2 from './Navbar2';
import { useState ,useEffect, useAuth} from 'react';
const Dashboard = (props) => {
   const [isAuthenticated, setIsAuthenticated] = useState(false);
    
  
    useEffect(() => {
  
    const auth = sessionStorage.getItem('isAuthenticated')=== 'true';
    setIsAuthenticated(auth);
    if(auth){
        const {setSite} = props
        setSite('dashboard')
        console.log('logged in',auth)
}},[]);
    if(!isAuthenticated){
        console.log('loggedout');
        <Navigate to={"/"} replace/>;
    }
  


  return null;
}


export default Dashboard;