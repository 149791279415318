import { useEffect, useState } from "react";
import EdProject from "./EdProject";
import EdContent from "./EdContent";
import DelProject from "./DelProject";
import DelContent from "./DelContent";
const Edit = () =>{
    const [editChoice, setEditChoice] = useState(1);
    return (
        // upload project
        // list all projects
        // Upload project entry for?<div className="ContentBox"
       <div className="ContentBox">
        <span className="SpanPositioning">
        <button className="SelectEUBtn" onClick={() => setEditChoice(1)}>Edit Project</button>
        <button className="SelectEUBtn"onClick={() => setEditChoice(2)}>Edit Content</button>
      {/*  <button className="SelectEUBtn" onClick={() => setEditChoice(3)}>Delete Project</button>
        <button className="SelectEUBtn"onClick={() => setEditChoice(4)}>Delete Content</button>*/}
        {editChoice === 1 && <EdProject></EdProject>}
        {editChoice === 2 && <EdContent></EdContent>}
       {/* {editChoice === 3 && <DelProject></DelProject>}
        {editChoice === 4 && <DelContent></DelContent>}*/}
    </span>
    </div>
    );
}
export default Edit;