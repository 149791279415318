import { useEffect, useState } from "react";
import '../Styles/private.css';
import UpProject from "./UpProject";
import UpContent from "./UpContent";
const Upload = () =>{
    const [upChoice, setUpChoice] = useState(1);

return (
    // upload project
    // list all projects
    //upload project entry for?<div className="ContentBox"
   <div className="ContentBox">
    <span className="SpanPositioning">
    <button className="SelectEUBtn" onClick={() => setUpChoice(1)}>Upload Project</button>
    <button className="SelectEUBtn" onClick={() => setUpChoice(2)}>Upload Content</button>
    {upChoice === 1 && <UpProject></UpProject>}
    {upChoice === 2 && <UpContent></UpContent>}
</span>
</div>
);
}
export default Upload;